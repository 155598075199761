import { useCallback, useEffect, useRef, useState } from 'react';
import './App.css';
import AWS from 'aws-sdk';
import { Buffer } from "buffer";
import "./styles/index.scss";
import {
  Alert,
  Box,
  Button,
  createTheme,
  Fab,
  ImageList,
  ImageListItem,
  PaletteColor,
  ThemeProvider
} from '@mui/material';
import { FormField } from './Upload.styles';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import DownloadIcon from '@mui/icons-material/Download';
// import ImageViewer from 'react-simple-image-viewer';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { Modal } from './components/Modal';

Buffer.from("anything", "base64");
window.Buffer = window.Buffer || require("buffer").Buffer;

declare module "@mui/material/styles" {
  interface Palette {
    white: string;
  }
  interface PaletteOptions {
    white: PaletteColor;
  }
}

declare module "@mui/material/Button" {
  interface ButtonPropsColorOverrides {
    white: true;
  }
}

/* Material UI theme settings */
const { palette } = createTheme();
const theme = createTheme({
  palette: {
    background: {
      default: "#455440",
    },
    text: {
      primary: "#EBEBEB",
    },
    primary: { main: "#3c202a" },
    secondary: { main: "#c69241" },
    error: { main: "#da0222" },
    white: palette.augmentColor({
      color: {
        main: "#EBEBEB",
      },
    }),
  },
  typography: {
    button: {
      textTransform: "none",
    },
    "fontFamily": "OpenSans",
  },
});

function App() {
  const [fileList, setFileList] = useState<Array<string>>([]);
  const [photoList, setPhotoList] = useState<Array<string>>([]);
  const fileInputField = useRef<any>();
  const scrollRef = useRef<any>(null);
  const [currentImage, setCurrentImage] = useState<number>(0);
  const [isViewerOpen, setIsViewerOpen] = useState<boolean>(false);
  const [alert, setAlert] = useState<{ message: string, type: string }>({ message: "", type: "success" });
  const [touchStart, setTouchStart] = useState(null)
  const [touchEnd, setTouchEnd] = useState(null)

  const minSwipeDistance = 50

  const onTouchStart = (e: any) => {
    setTouchEnd(null) // otherwise the swipe is fired even with usual touch events
    setTouchStart(e.targetTouches[0].clientX)
  }

  const onTouchMove = (e: any) => setTouchEnd(e.targetTouches[0].clientX)

  const onTouchEnd = () => {
    if (!touchStart || !touchEnd) return
    const distance = touchStart - touchEnd
    const isLeftSwipe = distance > minSwipeDistance
    const isRightSwipe = distance < -minSwipeDistance
    if (isLeftSwipe || isRightSwipe) console.log('swipe', isLeftSwipe ? 'left' : 'right')
    // add your conditional logic here
    if (isLeftSwipe) {
      setCurrentImage(prev => prev + 1)
    }
    if (isRightSwipe) {
      setCurrentImage(prev => prev - 1)
    }
  }

  const S3_BUCKET = process.env.REACT_APP_BUCKET ? process.env.REACT_APP_BUCKET : "ritchie-wedding-items";
  const REGION = process.env.REACT_APP_REGION ? process.env.REACT_APP_REGION : "us-east-2";

  AWS.config.update({
    accessKeyId: process.env.REACT_APP_ACCESS_KEY ? process.env.REACT_APP_ACCESS_KEY : 'AKIAQKGGXGXGDXN6GNPJ',
    secretAccessKey: process.env.REACT_APP_SECRET_KEY ? process.env.REACT_APP_SECRET_KEY : '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'
  })

  const myBucket = new AWS.S3({
    params: { Bucket: S3_BUCKET },
    region: REGION,
  })

  const uploadImageToS3 = (file: any, key: string, type: string) => {
    const params: AWS.S3.PutObjectRequest = {
      Body: file,
      Bucket: S3_BUCKET,
      Key: key, //make sure this unique
      ContentType: type
    };

    console.log("params", params);
    myBucket.putObject(params).send((err) => {
      if (err) console.log(err);
      else setAlert({
        message: "Files uploaded!",
        type: "success"
      });
    });
  };

  const handleUploadBtnClick = () => {
    fileInputField.current.click();
  };

  const handleNewFileUpload = (e: any) => {
    e.preventDefault();
    console.log(e.target);
    const { files: newFiles } = e.target;
    if (newFiles.length) {
      for (let file of newFiles) {
        if (file.size > 2000000000) {
          setAlert({
            message: "File is too large",
            type: "error"
          });
        } else {
          var split = file.name.split(".");
          var key = Date.now() + "" + Math.floor(100000 + Math.random() * 900000) + "." + split[split.length - 1];
          var type = ""
          if (split[split.length - 1] === "mov") {
            type = "video/mp4"
          } else if (split[split.length - 1] === "mov") {
            type = "video/mp4"
          } else {
            type = `image/${split[split.length - 1]}`
          }
          uploadImageToS3(file, key, type);
        }
      }
      setTimeout(() => {
        setFileList([]);
        setPhotoList([]);
        getPhotoList();
      }, 10000)
    }
  };

  const openImageViewer = useCallback((item: string) => { //pass in file key
    //find new key in photo list
    var photoIndex = photoList.findIndex(x => x === item);
    setCurrentImage(photoIndex < 0 ? 0 : photoIndex);
    setIsViewerOpen(true);
  }, [photoList]);

  const closeImageViewer = () => {
    setCurrentImage(0);
    setIsViewerOpen(false);
  };

  useEffect(() => {
    getPhotoList();
    setAlert({ message: "", type: "success" });
    // eslint-disable-next-line
  }, [currentImage]);

  const scrollToTop = () => {
    window.scrollTo({
      top: scrollRef.current.offsetTop,
      behavior: "smooth"
    });
  };

  function getPhotoList() {
    //get all keys to access files in s3 bucket
    const listAllKeys = (params: any, out: any = []) => new Promise((resolve, reject) => {
      myBucket.listObjectsV2(params).promise()
        .then(({ Contents, IsTruncated, NextContinuationToken }: any) => { //limit is 1000 objects from the bucket
          out.push(...Contents);
          !IsTruncated ? resolve(out) : resolve(listAllKeys(Object.assign(params, { ContinuationToken: NextContinuationToken }), out));
        })
        .catch(reject);
    });

    listAllKeys({ Bucket: process.env.REACT_APP_BUCKET })
      .then((val: any) => {
        var list = val.map((item: any) => { //video or photo
          var split = item.Key.split(".");
          if (split[split.length - 1] === "mov") {
            return process.env.REACT_APP_CLOUDFRONT_URL + item.Key
          }
          if (split[split.length - 1] === "mp4") {
            return process.env.REACT_APP_CLOUDFRONT_URL + item.Key
          } else {
            return process.env.REACT_APP_CONVERTED_URL + item.Key
          }
        });
        // only images but messes up the image preview indexes
        var photoList = val.map((photo: any) => process.env.REACT_APP_CONVERTED_URL + photo.Key);
        photoList = photoList.filter((x: string) => (x.split(".")[x.split(".").length - 1] !== "mov"))
        photoList = photoList.filter((x: string) => (x.split(".")[x.split(".").length - 1] !== "mp4"))
        setFileList(list.reverse()); //most recent ones first
        setPhotoList(photoList.reverse());
      })
      .catch(console.log);
  }

  function downloadImage() {
    var split = photoList[currentImage].split(/[\s./]+/); //split by . and /
    //need to use cloudfront url
    fetch(process.env.REACT_APP_CLOUDFRONT_URL + split[split.length - 2] + "." + split[split.length - 1], {
      method: "GET",
    })
      .then(response => response.blob())
      .then(blob => {
        const blobURL = URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = blobURL;
        a.download = Date.now() + "" + Math.floor(100000 + Math.random() * 900000) + "." + split[split.length - 1];
        document.body.appendChild(a);
        a.click();
      })
      .catch(() => {
        closeImageViewer()
        setAlert({ message: "Download Failed", type: "error" })
      });
  }

  function srcset(image: string, width: number, cols = 1) {
    return {
      src: `${image}?w=${width * cols}&fit=crop&auto=format`,
      srcSet: `${image}?w=${width * cols}&fit=crop&auto=format&dpr=2 2x`,
    };
  }

  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        {alert.message !== "" && (
          <Alert severity={alert.type === "success" ? "success" : "error"}>{alert.message}</Alert>
        )}

        <h1 className='amsterdam' ref={scrollRef}>Kristi and Will</h1>
        <div>Upload and share your photos from the day!</div>

        <div>
          <FormField
            accept=".jpg,.png,.jpeg,.mp4,.mov" //,.svg,.heic,.heif,.gif
            type="file"
            ref={fileInputField}
            onChange={handleNewFileUpload}
            title=""
            value=""
            multiple
          />
          <Button variant="contained" onClick={handleUploadBtnClick} sx={{ width: "100%" }}>
            Upload Files
          </Button>
        </div>

        <Box>
          <ImageList gap={10}>
            {fileList.map((item: string, index: number) => {
              if ((item.split(".")[item.split(".").length - 1] === "mov")) {
                return (
                  <ImageListItem key={index} cols={2} rows={1}>
                    <video
                      controls
                      style={{ objectFit: "cover", display: "block", width: "100%", height: "100%" }}
                      {...srcset(item, 250, 2)}
                    >
                      <source src={item} type="video/mp4" />
                    </video>
                  </ImageListItem>
                )
              } else if ((item.split(".")[item.split(".").length - 1] === "mp4")) {
                return (
                  <ImageListItem key={index} cols={2} rows={1}>
                    <video
                      controls
                      style={{ objectFit: "cover", display: "block", width: "100%", height: "100%" }}
                      {...srcset(item, 250, 2)}
                    >
                      <source src={item} type="video/mp4" />
                    </video>
                  </ImageListItem>
                )
              } else
                return (
                  <ImageListItem key={index} cols={1} rows={1}>
                    <img
                      {...srcset(item, 250, 1)}
                      // srcSet={`${item}?w=248&fit=crop&auto=format&dpr=2 2x`}
                      // src={`${item}?w=248&fit=crop&auto=format`}
                      alt={item}
                      loading="lazy"
                      onClick={() => openImageViewer(item)}
                    />
                  </ImageListItem>
                )
            })}
          </ImageList>
        </Box>

        <Box sx={{ '& > :not(style)': { m: 1 }, position: "fixed", bottom: "1vh", right: "3vw" }}>
          <Fab color="primary" aria-label="add" onClick={() => scrollToTop()}>
            <ArrowUpwardIcon />
          </Fab>
        </Box>

        {/* {isViewerOpen && (
          <div onTouchStart={onTouchStart} onTouchMove={onTouchMove} onTouchEnd={onTouchEnd}>
            <ImageViewer
              src={photoList}
              currentIndex={currentImage}
              disableScroll={false}
              closeOnClickOutside={true}
              onClose={closeImageViewer}
              leftArrowComponent={
                <button onClick={() => setCurrentImage(prev => prev - 1)}>
                  <ChevronLeftIcon fontSize='large' />
                </button>
              }
              rightArrowComponent={
                <button onClick={() => setCurrentImage(prev => prev + 1)}>
                  <ChevronRightIcon fontSize='large' />
                </button>
              }

            />
            <Box sx={{ '& > :not(style)': { m: 1 }, position: "fixed", bottom: "1vh", right: "3vw", zIndex: "10000000000" }}>
              <Fab color="primary" aria-label="add" onClick={() => downloadImage()}>
                <DownloadIcon />
              </Fab>
            </Box>
          </div>
        )} */}
        <Modal
          isOpen={isViewerOpen}
          onRequestClose={closeImageViewer}
        >
          <div onTouchStart={onTouchStart} onTouchMove={onTouchMove} onTouchEnd={onTouchEnd}>
            {currentImage > 0 && (
              <button style={{ position: "absolute", height: "100%", width: "20%", top: "0", left: "0" }} onClick={() => setCurrentImage(prev => prev - 1)}>
                <ChevronLeftIcon fontSize='large' />
              </button>
            )}

            <div >
              <img style={{ objectFit: "contain", width: "100%" }} src={photoList[currentImage]} alt={photoList[currentImage]} />
            </div>
            {currentImage < photoList.length - 1 && (
              <button style={{ position: "absolute", height: "100%", width: "20%", top: "0", right: "0" }} onClick={() => setCurrentImage(prev => prev + 1)}>
                <ChevronRightIcon fontSize='large' />
              </button>
            )}


            <Box sx={{ '& > :not(style)': { m: 1 }, position: "fixed", bottom: "1vh", right: "3vw", zIndex: "10000000000" }}>
              <Fab color="primary" aria-label="add" onClick={() => downloadImage()}>
                <DownloadIcon />
              </Fab>
            </Box>
          </div>
        </Modal>
      </ThemeProvider>
    </div>
  );
}

export default App;
